import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../assets/logo.png";
import "../styles/style.css";
import '../styles/Header.css';
import Login from "../assets/Login.svg";
import Search from "../assets/search.svg";
import Globe from "../assets/globe.svg";
import Cart from "../assets/cart.svg";

const Header = () => {
  useEffect(() => {

    const toggleScrolled = () => {
      const selectBody = document.querySelector('body');
      const selectHeader = document.querySelector('#header');
      if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
      window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    };

    document.addEventListener('scroll', toggleScrolled);
    window.addEventListener('load', toggleScrolled);

    // Mobile nav toggle
    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
    const mobileNavToggle = () => {
      document.querySelector('body').classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-x');
      mobileNavToggleBtn.classList.toggle('bi-list');
    };
    mobileNavToggleBtn.addEventListener('click', mobileNavToggle);

    // Hide mobile nav on same-page/hash links
    document.querySelectorAll('#navmenu a').forEach(navmenu => {
      navmenu.addEventListener('click', () => {
        if (document.querySelector('.mobile-nav-active')) {
          mobileNavToggle();
        }
      });
    });

    // Toggle mobile nav dropdowns
    document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
      navmenu.addEventListener('click', function (e) {
        e.preventDefault();
        this.parentNode.classList.toggle('active');
        this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
        e.stopImmediatePropagation();
      });
    });

    return () => {
      // Clean up event listeners
      document.removeEventListener('scroll', toggleScrolled);
      mobileNavToggleBtn.removeEventListener('click', mobileNavToggle);
    };
  }, []);

  return (
    <header id="header" className="header sticky-top">
      <div className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-end">
          <div className="topbar-item">
            <img src={Login} alt="Login" />
            <span>登入</span>
          </div>
          <div className="topbar-divider"></div>
          <div className="topbar-item">
            <img src={Search} alt="search" />
            <span>搜尋</span>
          </div>
          <div className="topbar-divider"></div>
          <div className="topbar-item">
            <img src={Globe} alt="globe" />
            <span style={{ marginLeft: '4px' }}>台灣</span>
          </div>
        </div>
      </div>

      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <Link to="/home" className="logo d-flex align-items-center me-auto">
            <img src={Logo} alt="Logo" width={91} />
          </Link>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/why-choose-us">為什麼選擇Big-O</Link>
              </li>
              <li className="dropdown">
                <Link to=""><span>核心產品</span> <i className="bi bi-chevron-down toggle-dropdown"></i></Link>
                <ul>
                  <li className="dropdown">
                    <Link to="">
                      {/* <div className='dropdown-item'> */}
                      <span>微型商務解決方案</span>
                      <i className="bi bi-chevron-right toggle-dropdown"></i>
                      {/* </div> */}
                    </Link>
                    <ul>
                      <li><Link to="/micro-business-solutions-feature">特色總覽</Link></li>
                      <li><Link to="/pricing">方案費用</Link></li>
                      <li><Link to="/micro-business-solutions-store">自主開店</Link></li>
                      <li><Link to="/theme-design">自選版型</Link></li>
                    </ul>
                  </li>
                  <li><Link to="">教育自媒體解決方案</Link></li>
                  <li><Link to="">整合式醫療服務解決方案</Link></li>
                  <li><Link to="">智慧建築與設計應用解決方案</Link></li>
                  <li><Link to="">金融實戰應用解決方案</Link></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href=""><span>最新案例</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="/leading-brand">網路開店案例</a></li>
                  {/*<li><a href="">教育自媒體案例 </a></li>*/}
                  {/*<li><a href="">整合式醫療應用案例</a></li>*/}
                  {/*<li><a href="">智慧建築與設計案例</a></li>*/}
                  {/*<li><a href="">金融實戰應用案例 </a></li>*/}
                </ul>
              </li>
              <li className="dropdown">
                <a href=""><span>策略聯盟</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="/business-plan">異業結盟計畫</a></li>
                  <li><a href="/partner-ship">尋找策略合作夥伴</a></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href=""><span>資源與活動</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="/resources">白皮書及指南</a></li>
                  {/*<li><a href="">網路研討會</a></li>*/}
                  {/*<li><a href="">活動</a></li>*/}
                </ul>
              </li>
              <li className="dropdown">
                <a href=""><span>新聞中心</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="/news-highlights">展翅高飛焦點</a></li>
                </ul>
              </li>
              <li>
                <Link to="/blog">雲端數位智慧學院</Link>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi-list"></i>
          </nav>
          <a className="cta-btn d-none d-sm-block" href="#appointment">
            <img src={Cart} alt="cart" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
