import React, { useState, useEffect } from 'react';
import '../styles/MicroBusinessSolutionsStore.css';
import BannerSection from '../components/BannerSection';
import PricingBanner from '../assets/buyyourself-banner.png';
import StoreImage from '../assets/storeImage.png';
import featureIcon from '../assets/icon/list.svg';
import { useFindProductsByCategoryIdAndPaymentTypeQuery } from '../hooks/Product';
import { ProductItem } from './ProductItem';

const MicroBusinessSolutionsStore = () => {
  const { data: basicPlanProducts, isFetched: isBasicPlanProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_BASIC_PLAN_CATEGORY_ID!, // the mainCategoryId for '基礎方案'
    'BUYOUT'
  );

  const { data: storefrontProducts, isFetched: isStorefrontProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_STOREFRONT_CATEGORY_ID!, // the mainCategoryId for '商店建立'
    'BUYOUT'
  );

  const { data: productManagementProducts, isFetched: isProductManagementProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_PRODUCT_MANAGEMENT_CATEGORY_ID!, // the mainCategoryId for '商品管理'
    'BUYOUT'
  );

  const { data: paymentLogisticsManagementProducts, isFetched: isPaymentLogisticsManagementProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_PAYMENT_LOGISTIC_MANAGEMENT_CATEGORY_ID!, // the mainCategoryId for '金物流串接'
    'BUYOUT'
  );

  const { data: orderManagementProducts, isFetched: isOrderManagementProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_ORDER_MANAGEMENT_CATEGORY_ID!, // the mainCategoryId for '訂單管理'
    'BUYOUT'
  );

  const { data: memberManagementProducts, isFetched: isMemberManagementProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_MEMBER_MANAGEMENT_CATEGORY_ID!, // the mainCategoryId for '會員管理'
    'BUYOUT'
  );

  const { data: templateProducts, isFetched: isTemplateProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_TEMPLATE_CATEGORY_ID!, // the mainCategoryId for '自選版型'
    'BUYOUT'
  );

  const { data: thirdPartyProducts, isFetched: isThirdPartyProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_THIRD_PARTY_CATEGORY_ID!, // the mainCategoryId for '第三方串接'
    'BUYOUT'
  );

  const { data: statisticProducts, isFetched: isStatisticProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_STATISTIC_CATEGORY_ID!, // the mainCategoryId for '數據報表'
    'BUYOUT'
  );

  const { data: adminManagementProducts, isFetched: isAdminManagementProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_ADMIN_MANAGEMENT_CATEGORY_ID!, // the mainCategoryId for '營運管理'
    'BUYOUT'
  );

  const { data: bannerProducts, isFetched: isBannerProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_BANNER_CATEGORY_ID!, // the mainCategoryId for '橫幅管理'
    'BUYOUT'
  );

  const { data: pageProducts, isFetched: isPageProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_PAGE_CATEGORY_ID!, // the mainCategoryId for '頁面管理'
    'BUYOUT'
  );

  const { data: couponProducts, isFetched: isCouponProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_COUPON_CATEGORY_ID!, // the mainCategoryId for '領券管理'
    'BUYOUT'
  );

  const { data: creditProducts, isFetched: isCreditProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_CREDIT_CATEGORY_ID!, // the mainCategoryId for '紅利積點管理'
    'BUYOUT'
  );

  const { data: discountProducts, isFetched: isDiscountProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_DISCOUNT_CATEGORY_ID!, // the mainCategoryId for '促銷管理'
    'BUYOUT'
  );

  const { data: revenueShareProducts, isFetched: isRevenueShareProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_REVENUE_SHARE_CATEGORY_ID!, // the mainCategoryId for '分潤管理'
    'BUYOUT'
  );

  const { data: giftProducts, isFetched: isGiftProductsFetched } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_GIFT_CATEGORY_ID!, // the mainCategoryId for '贈品管理'
    'BUYOUT'
  );


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState('store-setup');

  const renderContent = () => {
    switch (activeTab) {
      case 'basic-plan':
        return (
          <div className="tab-pane fade show active">
            <h3>基礎方案</h3>
            <p>基礎方案的相關內容。</p>
            <div className="row">
              {/* 基礎方案 */}
              {isBasicPlanProductsFetched && basicPlanProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'store-setup':
        return (
          <div className="tab-pane fade show active">
            <h3>商店建立</h3>
            <p>持續優化系統效能及安全防護，為了你的成長而做的萬全準備。</p>
            {/* <div className="row mt-4">
              <div className="col-md-12">
                <img src={StoreImage} alt="Store Setup" className="img-fluid mb-3" />
              </div>
            </div> */}
            <div className="row">
              {/* 商品列表 */}
              {isStorefrontProductsFetched && storefrontProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'inventory-management':
        return (
          <div className="tab-pane fade show active">
            <h3>商品管理</h3>
            <p>商品管理的相關內容。</p>
            <div className="row">
              {/* 商品管理 */}
              {isProductManagementProductsFetched && productManagementProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'logistics-connection':
        return (
          <div className="tab-pane fade show active">
            <h3>金物流串接</h3>
            <p>金物流串接的相關內容。</p>
            <div className="row">
              {/* 金物流串接 */}
              {isPaymentLogisticsManagementProductsFetched && paymentLogisticsManagementProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'regulations':
        return (
          <div className="tab-pane fade show active">
            <h3>訂單管理</h3>
            <p>訂單管理的相關內容。</p>
            <div className="row">
              {/* 訂單管理 */}
              {isOrderManagementProductsFetched && orderManagementProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'member-management':
        return (
          <div className="tab-pane fade show active">
            <h3>會員管理</h3>
            <p>會員管理的相關內容。</p>
            <div className="row">
              {/* 會員管理 */}
              {isMemberManagementProductsFetched && memberManagementProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'template':
        return (
          <div className="tab-pane fade show active">
            <h3>自選版型</h3>
            <p>自選版型的相關內容。</p>
            <div className="row">
              {/* 自選版型 */}
              {isTemplateProductsFetched && templateProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'third-party':
        return (
          <div className="tab-pane fade show active">
            <h3>第三方串接</h3>
            <p>第三方串接的相關內容。</p>
            <div className="row">
              {/* 第三方串接 */}
              {isThirdPartyProductsFetched && thirdPartyProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'statistic':
        return (
          <div className="tab-pane fade show active">
            <h3>數據報表</h3>
            <p>數據報表的相關內容。</p>
            <div className="row">
              {/* 數據報表 */}
              {isStatisticProductsFetched && statisticProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'admin-management':
        return (
          <div className="tab-pane fade show active">
            <h3>營運管理</h3>
            <p>營運管理的相關內容。</p>
            <div className="row">
              {/* 營運管理 */}
              {isAdminManagementProductsFetched && adminManagementProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'banner':
        return (
          <div className="tab-pane fade show active">
            <h3>橫幅管理</h3>
            <p>橫幅管理的相關內容。</p>
            <div className="row">
              {/* 橫幅管理 */}
              {isBannerProductsFetched && bannerProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'page':
        return (
          <div className="tab-pane fade show active">
            <h3>頁面管理</h3>
            <p>頁面管理的相關內容。</p>
            <div className="row">
              {/* 頁面管理 */}
              {isPageProductsFetched && pageProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'coupon':
        return (
          <div className="tab-pane fade show active">
            <h3>領券管理</h3>
            <p>領券管理的相關內容。</p>
            <div className="row">
              {/* 領券管理 */}
              {isCouponProductsFetched && couponProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'credit':
        return (
          <div className="tab-pane fade show active">
            <h3>紅利積點管理</h3>
            <p>紅利積點管理的相關內容。</p>
            <div className="row">
              {/* 紅利積點管理 */}
              {isCreditProductsFetched && creditProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'discount':
        return (
          <div className="tab-pane fade show active">
            <h3>促銷管理</h3>
            <p>促銷管理的相關內容。</p>
            <div className="row">
              {/* 促銷管理 */}
              {isDiscountProductsFetched && discountProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'revenue-share':
        return (
          <div className="tab-pane fade show active">
            <h3>分潤管理</h3>
            <p>分潤管理的相關內容。</p>
            <div className="row">
              {/* 分潤管理 */}
              {isRevenueShareProductsFetched && revenueShareProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'gift':
        return (
          <div className="tab-pane fade show active">
            <h3>贈品管理</h3>
            <p>贈品管理的相關內容。</p>
            <div className="row">
              {/* 贈品管理 */}
              {isGiftProductsFetched && giftProducts?.map((product) => (
                <div key={product.id} className="col-6 col-md-4 mb-4">  {/* Each product takes 3 columns (4 items per row) */}
                  <ProductItem key={product.id} product={product} />
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section className="MicroBusinessSolutionsStore">
      <div>
        <BannerSection
          category="自主開店"
          title="業界首創-彈性選購，客製化需求"
          subtitle='自行採買結帳，滿足您雲端網店所需！請<a href="/contact">聯繫我們</a>'
          backgroundImage={PricingBanner}
        />
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3">
            <div className="nav flex-column nav-pills">
              <button
                className={`nav-link ${activeTab === 'basic-plan' ? 'active' : ''}`}
                onClick={() => setActiveTab('basic-plan')}
              >
                基礎方案
              </button>
              <button
                className={`nav-link ${activeTab === 'store-setup' ? 'active' : ''}`}
                onClick={() => setActiveTab('store-setup')}
              >
                商店建立
              </button>
              <button
                className={`nav-link ${activeTab === 'inventory-management' ? 'active' : ''}`}
                onClick={() => setActiveTab('inventory-management')}
              >
                商品管理
              </button>
              <button
                className={`nav-link ${activeTab === 'logistics-connection' ? 'active' : ''}`}
                onClick={() => setActiveTab('logistics-connection')}
              >
                金物流串接
              </button>
              <button
                className={`nav-link ${activeTab === 'regulations' ? 'active' : ''}`}
                onClick={() => setActiveTab('regulations')}
              >
                訂單管理
              </button>
              <button
                className={`nav-link ${activeTab === 'member-management' ? 'active' : ''}`}
                onClick={() => setActiveTab('member-management')}
              >
                會員管理
              </button>
              <button
                className={`nav-link ${activeTab === 'template' ? 'active' : ''}`}
                onClick={() => setActiveTab('template')}
              >
                自選版型
              </button>
              <button
                className={`nav-link ${activeTab === 'third-party' ? 'active' : ''}`}
                onClick={() => setActiveTab('third-party')}
              >
                第三方串接
              </button>
              <button
                className={`nav-link ${activeTab === 'statistic' ? 'active' : ''}`}
                onClick={() => setActiveTab('statistic')}
              >
                數據報表
              </button>
              <button
                className={`nav-link ${activeTab === 'admin-management' ? 'active' : ''}`}
                onClick={() => setActiveTab('admin-management')}
              >
                營運管理
              </button>
              <button
                className={`nav-link ${activeTab === 'banner' ? 'active' : ''}`}
                onClick={() => setActiveTab('banner')}
              >
                橫幅管理
              </button>
              <button
                className={`nav-link ${activeTab === 'page' ? 'active' : ''}`}
                onClick={() => setActiveTab('page')}
              >
                頁面管理
              </button>
              <button
                className={`nav-link ${activeTab === 'coupon' ? 'active' : ''}`}
                onClick={() => setActiveTab('coupon')}
              >
                領券管理
              </button>
              <button
                className={`nav-link ${activeTab === 'credit' ? 'active' : ''}`}
                onClick={() => setActiveTab('credit')}
              >
                紅利積點管理
              </button>
              <button
                className={`nav-link ${activeTab === 'discount' ? 'active' : ''}`}
                onClick={() => setActiveTab('discount')}
              >
                促銷管理
              </button>
              <button
                className={`nav-link ${activeTab === 'revenue-share' ? 'active' : ''}`}
                onClick={() => setActiveTab('revenue-share')}
              >
                分潤管理
              </button>
              <button
                className={`nav-link ${activeTab === 'gift' ? 'active' : ''}`}
                onClick={() => setActiveTab('gift')}
              >
                贈品管理
              </button>
            </div>
          </div>
          <div className="col-md-9">
            {renderContent()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MicroBusinessSolutionsStore;
