import React, { useEffect } from 'react';
// TODO: remove bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/MicroBusinessSolutionsFeature.css';
import feature1Image from '../assets/MicroBusinessSolutions/02.png';
import feature2Image from '../assets/MicroBusinessSolutions/03.png';
import feature3Image from '../assets/MicroBusinessSolutions/04.png';
import CallToActionTwo from '../components/CallToActionTwo';
import featureIcon from '../assets/icon/list.svg'; 

const MicroBusinessSolutionsFeature = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="micro-business-solutions-feature">
      <section className="background-section d-flex align-items-center">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-md-6">
          </div>
          <div className="col-md-6">
            <h2 className="display-5">無牆開店 SO EASY<br />立即部署您的雲端網路生意 </h2>
            <p className="lead mt-4">
            想在網路做生意嗎？ 虛擬商城與數位轉型之旅盡在Big-O。從創業起，提供全方位雲端創業服務，一站式立即完成，讓首次創業的您，不再徬徨，立刻上手！
            </p>
            <a href="/contact" class="custom-button-bottom-white mt-3">立即聯絡我們 →</a>
    
          </div>
        </div>
      </div>
    </section>
    <section className="feature-overview-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <img src={feature1Image} alt="Feature" className="img-fluid rounded" />
          </div>
          <div className="col-md-7">
            <h3 className="text-warning">No-Code</h3>
            <h2 className="mb-3">也能創造質感官網</h2>
            <p>
            架設形象或購物網站，無需花費太多時間，透過Big-O後台的簡便操作模式，就能輕鬆完成，為您的雲端網路生意開啟財源之門
            </p>
          </div>
        </div>
        
        <div className="row mt-5">
          <div className="col-md-4">
            <h4>直覺式操作</h4>
            <p>透過頁面設計系統來直覺對應所選之套版編輯，從而獲得更多時間做其他行銷。</p>
          </div>
          <div className="col-md-4">
            <h4>多元多樣化設計</h4>
            <p>提供各產業相應之系列套版，由專業設計師進行網站的主題設計，可立即預覽所選，立刻獲得具質感及專業的平台。</p>
          </div>
          <div className="col-md-4">
            <h4>無抽單機制</h4>
            <p>創業不易，透過單純繳付系統維護費，還可獲得虛擬貨幣折抵次年年費，減輕您創業的負擔。</p>
          </div>
        </div>
      </div>
    </section>

    <section className="single-page-store-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h3 className="text-warning">實現高效能運用</h3>
            <h2 className="mb-3">用雲端及自動化吸引目光</h2>
            <p className="lead mb-4">
            透過雲端部署來為您建置平台，並提供客戶便利之操作系統，無需撰寫程式及IT開發人員用現有模板完成No-Code目標！用心服務及快速佈建，吸引客戶目光！

            </p>
          </div>
          <div className="col-md-5">
            <div className="d-flex justify-content-center">
              <img src={feature2Image} alt="Store Image" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="order-management-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="image-container d-flex justify-content-center">
              <img src={feature3Image} alt="Order Management Tools" className="img-fluid rounded" />
            </div>
          </div>
          <div className="col-md-6">
            <h3 className="text-warning mb-2">彈性客製化</h3>
            <h2 className="mb-4">提供超彈性便利自選功能開店</h2>
            <p className="lead">
            官網即網店，提供四種方案給您選擇，若有想自己選擇功能，可以自選功能，可完成一站式購足，讓您輕鬆減輕開店工具負擔
            </p>
            <div className="features-list row">
              <div className="col-sm-6">
                <ul className="list-unstyled">
                  <li><img src={featureIcon} alt="Feature" className="me-2" />⍬方案</li>
                  <li><img src={featureIcon} alt="Feature" className="me-2" />⍺方案</li>
                  <li><img src={featureIcon} alt="Feature" className="me-2" />µ方案</li>
                </ul>
              </div>
              <div className="col-sm-6">
                <ul className="list-unstyled">
                  <li><img src={featureIcon} alt="Feature" className="me-2" />Ω方案</li>
                  <li><img src={featureIcon} alt="Feature" className="me-2" />Basic方案-自主開店</li>
                  <li><img src={featureIcon} alt="Feature" className="me-2" />Basic方案-自選版型</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <CallToActionTwo
        title="立即選購"
        subtitle="Big-O為您的雲端網路生意做好完善的工具，從官網即網店架設、金物流串接及資金節約等都為您想好了"
        buttonText="立即聯絡我們"
        buttonLink="/contact"
      />
    
    </div>
  );
};

export default MicroBusinessSolutionsFeature;
