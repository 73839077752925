import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import LeadingBrand from "./pages/LeadingBrand";
import BusinessPlan from "./pages/BusinessPlan";
import Partnership from "./pages/Partnership";
import Resources from "./pages/Resources";
import NewsHighlight from "./pages/NewsHighlight";
import Blog from "./pages/Blog";
import ProductUpdates from "./pages/ProductUpdates";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import AboutUs from "./pages/AboutUs";
import WhyChooseUs from "./pages/WhyChooseUs";
import MicroBusinessSolutions from "./pages/MicroBusinessSolutions";
import MicroBusinessSolutionsFeature from "./pages/MicroBusinessSolutionsFeature";
import Pricing from "./pages/Pricing";
import MicroBusinessSolutionsStore from "./pages/MicroBusinessSolutionsStore";
import ThemeDesign from "./pages/ThemeDesign";
import ArticleThree from "./articles/ArticleThree";
import ArticleTwo from "./articles/ArticleTwo";
import ArticleOne from "./articles/ArticleOne";
import { MainLayout } from "./components/MainLayout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "leading-brand",
        element: <LeadingBrand />,
      },
      {
        path: "business-plan",
        element: <BusinessPlan />,
      },
      {
        path: "partner-ship",
        element: <Partnership />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
      {
        path: "news-highlights",
        element: <NewsHighlight />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "product-updates",
        element: <ProductUpdates />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "why-choose-us",
        element: <WhyChooseUs />,
      },
      {
        path: "micro-business-solutions",
        element: <MicroBusinessSolutions />,
      },
      {
        path: "article-one",
        element: <ArticleOne />,
      },
      {
        path: "article-two",
        element: <ArticleTwo />,
      },
      {
        path: "article-three",
        element: <ArticleThree />,
      },
      {
        path: "micro-business-solutions-feature",
        element: <MicroBusinessSolutionsFeature />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "micro-business-solutions-store",
        element: <MicroBusinessSolutionsStore />,
      },
      {
        path: "theme-design",
        element: <ThemeDesign />,
      },
    ],
  },
]);
